var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-content"},[_c('h2',{staticClass:"mb-4"},[_vm._v("Sales order list")]),_c('vue-good-table',{attrs:{"columns":_vm.columns,"line-numbers":true,"search-options":{
      enabled: true,
      placeholder: 'Search this table',
    },"pagination-options":{
      enabled: true,
      mode: 'records',
    },"styleClass":"tableOne vgt-table","selectOptions":{
      enabled: true,
      selectionInfoClass: 'table-alert__box',
    },"rows":_vm.rows},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field == 'button')?_c('span',[_c('a',{attrs:{"href":""}},[_c('i',{staticClass:"i-Eraser-2 text-25 text-success mr-2"}),_vm._v(" "+_vm._s(props.row.button))]),_c('a',{attrs:{"href":""}},[_c('i',{staticClass:"i-Close-Window text-25 text-danger"}),_vm._v(" "+_vm._s(props.row.button))])]):_vm._e()]}}])},[_c('div',{staticClass:"mb-3",attrs:{"slot":"table-actions"},slot:"table-actions"},[_c('b-button',{staticClass:"btn-rounded",attrs:{"variant":"primary"}},[_vm._v(" Add Button ")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }